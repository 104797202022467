import type {I18NOptions} from '@/types';

import {getForumsUrl, helpUrl, merchantSupportUrl} from '../utils';

export default function support({t, site}: I18NOptions) {
  const languageCode = site.languageCode || site.locale?.split('-')[0] || 'en';
  const eventsUrl = () => {
    switch (site.locale) {
      case 'en-BE':
      case 'en-NO':
      case 'en-ID':
      case 'en-PH':
      case 'en-ZA':
      case 'en-MY':
      case 'en-NG':
        return 'https://events.shopify.com';
      case 'de-BE':
        return 'https://events.shopify.com/deutschland';
      case 'es':
      case 'es-CO':
      case 'es-MX':
        return 'https://events.shopify.com/espanol';
      default:
        return `https://community.shopify.com/c/Shopify-Community/ct-p/en/home?utm_campaign=homepage&utm_content=${languageCode}&utm_medium=web&utm_source=shopify`;
    }
  };

  const navItems = [
    {
      content: t('footer:merchantSupport'),
      url: merchantSupportUrl(site),
    },
    {content: t('footer:helpCenter'), url: helpUrl(site)},
    {
      content: t('footer:hireAnExpert'),
      url: 'https://www.shopify.com/partners/directory',
    },
    {
      content: t('footer:shopifyAcademy'),
      url: 'https://academy.shopify.com?itcat=brochure&itterm=global-footer',
    },
  ];

  const communityEnabled = !['da', 'sv', 'ko', 'zh-HK', 'zh-TW'].includes(
    site.locale,
  );

  if (communityEnabled) {
    navItems.push({
      content: t('footer:forums'),
      url: `${getForumsUrl(
        site.languageCode,
      )}?utm_campaign=footer&utm_content=${site.locale.toLowerCase()}&utm_medium=web&utm_source=shopify`,
    });
  }

  const shopifyEventsLocales = [
    'en',
    'de-BE',
    'en-BE',
    'en-DK',
    'en-NO',
    'en-ID',
    'en-PH',
    'en-ZA',
    'en-MY',
    'fr-BE',
    'en-NG',
    'es',
    'es-CO',
    'es-MX',
    'en-HK',
  ];

  if (shopifyEventsLocales.includes(site.locale)) {
    navItems.push({
      content: t('footer:shopifyEvents'),
      url: eventsUrl(),
    });
  }

  return {
    content: t('footer:resources'),
    navItems,
  };
}
