import type {I18NOptions} from '@/types';

export default function solutions({t, localizePath, site}: I18NOptions) {
  const navItems = [
    {
      content: t('footer:onlineStoreBuilder'),
      url: localizePath('/online'),
    },
    {
      content: t('footer:featureTour'),
      url: localizePath('/website/builder'),
    },
  ];

  const tourEnabled = ![
    'da',
    'sv',
    'de-BE',
    'nl',
    'ko',
    'nl-BE',
    'fr-BE',
    'es',
    'es-CO',
    'es-MX',
    'zh-CN',
    'zh-HK',
    'zh-TW',
  ].includes(site.locale);

  if (tourEnabled) {
    navItems.push({
      content: t('footer:onlineStore'),
      url: localizePath('/tour/ecommerce-website'),
    });
  }

  return {
    content: t('footer:solutions'),
    navItems,
  };
}
